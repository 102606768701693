<script>
import throttle from 'lodash/throttle'
import appbarMixin from '@/v-shop/mixins/appbarMixin'
import { sync } from 'vuex-pathify'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [appbarMixin, hooksMixin('AppBar')],
	props: {
		showMenu: Boolean,
	},
	data() {
		return {
			lastScrollPosition: 0,
			slides: this.$srv('AppBarSlider', []),
			debouncedFn: null,
			scrollIsAtTop: true,
		}
	},
	mounted() {
		window.addEventListener('scroll', this.debouncedOnScrollY())
		if (!this.$ssr) {
			const { height } = this.$refs.nav.children.item(0).getBoundingClientRect()
			this.navbarHeight = height
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.debouncedFn)
	},
	watch: {
		'$route.name'(routeName) {
			if (!this.$b.d && routeName !== 'home') {
				this.$refs.nav.classList.remove('position-fixed')
			} else {
				this.$refs.nav.classList.remove('position-sticky')
			}
		},
	},
	methods: {
		onScrollY(event) {
			this.scrollIsAtTop = window.scrollY === 0

			if (this.$b.d && !this.config.stickyNavbar) return

			const navClassList = this.$refs.nav.classList
			const currentYPosition = window.scrollY
			const isSticky = currentYPosition > 0
			const showSticky = isSticky && currentYPosition < this.lastScrollPosition

			this.lastScrollPosition = currentYPosition

			this.forceTransparency = isSticky
			navClassList.toggle('slide-appbar', isSticky)
			navClassList.toggle('slide-appbar-show', showSticky)

			if (this.isHomeRoute && this.appbarTransparent) {
				navClassList.toggle('position-fixed', isSticky)
			} else {
				navClassList.toggle('position-sticky', isSticky)
			}
		},
		debouncedOnScrollY() {
			this.debouncedFn = throttle(this.onScrollY, 200)
			return this.debouncedFn
		},
	},
	computed: {
		navbarHeight: sync('shop/navbarHeight'),
		type() {
			const type = this.$srv('AppBarConfig.type', 'regular')
			if (type === 'regular' || type === 'transparent') return type
			// show round appbar on mobile only
			if (type === 'round' && this.$b.mt) return 'regular'
			return 'round'
		},
		forceTransparency: sync('shop/loading'),
		autoplayDelay() {
			return Number(this.$srv('AppBarSlider.autoplayDelay', 3))
		},
		imagesRatio() {
			return this.$b.xs ? 0.636 : 1.8604
		},
		positionStyles() {
			if (this.type == 'regular' && this.makeAppbarTransparent && !this.$b.d) {
				return { position: 'absolute' }
			} else if (this.type == 'round') {
				return { position: 'sticky', top: 0 }
			} else if (this.type == 'transparent' && this.$b.m && this.$route.name === 'home') {
				return { position: 'fixed !important' }
			}
			return ''
		},
	},
	cssVars: {
		selector: '.appbar',
	},
	eventsListeners: {
		focusMobileSearch() {
			const navClassList = this.$refs.nav.classList

			navClassList.toggle('slide-appbar', 1)
			navClassList.toggle('slide-appbar-show', 1)
		},
	},
}
</script>

<template>
	<div
		ref="nav"
		class="appbar d-flex flex-column align-center w100"
		:class="$b.d && 'position-fixed'"
		style="z-index: 5"
		:style="positionStyles"
	>
		<div class="w100" v-if="type === 'regular'">
			<v-expand-transition>
				<div v-show="scrollIsAtTop">
					<hook zone="top-msg">
						<AppBar-TopMsg />
					</hook>
				</div>
			</v-expand-transition>
			<AppBar-Top :style="makeAppbarTransparent && scrollIsAtTop && 'background: transparent!important'" />
			<hook zone="appbar-bottom">
				<div v-if="$b.td">
					<v-expand-transition>
						<div v-show="scrollIsAtTop">
							<AppBar-Bottom />
						</div>
					</v-expand-transition>
				</div>
			</hook>
		</div>
		<div v-else-if="type === 'transparent'" class="w100">
			<v-expand-transition>
				<div v-show="scrollIsAtTop">
					<hook zone="top-msg">
						<AppBar-TopMsg />
					</hook>
				</div>
			</v-expand-transition>
			<AppBarTransparent
				:class="{
					'appbar--top-styles': scrollIsAtTop && $route.name === 'home',
					'appbar--top-styles-with-hover-at-top': !config.disableHoverAtTop,
					'appbar--default-styles': !scrollIsAtTop || $route.name !== 'home',
				}"
			/>
		</div>
		<div v-else class="w100" :class="$route.name === 'home' ? 'absolute' : ''">
			<div>
				<v-expand-transition>
					<div v-show="scrollIsAtTop">
						<AppBar-TopMsg />
					</div>
				</v-expand-transition>
				<div class="d-flex justify-center">
					<AppBarRound class="base appbar-round w100 mt-2" />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.appbar {
	@mixin default-styles {
		background: var(--base);
		& ::v-deep {
			.appbar--transparent__logo {
				display: none !important;
			}
			.appbar--transparent__logo-alt {
				display: block !important;
			}

			.appbar__mega-menu__button-icon.v-icon,
			.appbar__mega-menu__button-text {
				color: var(--pricetext);
			}
			.appbar__bottom__menu-items .v-btn,
			.appbar__bottom__menu-items .v-btn .v-icon {
				color: var(--basetext) !important;
			}
			.searchfield {
				& .input-container {
					background: var(--discount) !important;
					border: 1px solid var(--linktext);
				}
				& input::placeholder {
					color: var(--ctatext);
				}
				& .icon,
				& .v-icon,
				& .v-input .v-input__slot span {
					color: var(--errortext) !important;
				}
			}

			.appbar__buttons__btn {
				color: var(--basetext) !important;
			}

			.mobile-menu-btn {
				color: var(--basetext) !important;
			}
		}
	}

	&--top-styles {
		background: var(--success);
		& ::v-deep {
			.appbar--transparent__logo {
				display: block;
			}
			.appbar--transparent__logo-alt {
				display: none;
			}
			.appbar__mega-menu__button-icon.v-icon,
			.appbar__mega-menu__button-text {
				color: var(--offertext);
			}
			.appbar__bottom__menu-items .v-btn,
			.appbar__bottom__menu-items .v-btn .v-icon {
				color: var(--primarytext) !important;
			}

			.searchfield {
				& .input-container {
					background: var(--offer) !important;
					border: 1px solid var(--strongtext);
				}
				& input::placeholder {
					color: var(--secondarytext);
				}
				& .icon,
				& .v-icon,
				& .v-input .v-input__slot span {
					color: var(--discounttext) !important;
				}
			}

			.appbar__buttons__btn {
				color: var(--primarytext) !important;
			}

			.mobile-menu-btn {
				color: var(--primarytext) !important;
			}
		}

		&-with-hover-at-top:hover {
			@include default-styles;
		}
	}

	&--default-styles {
		@include default-styles;
	}
}
.absolute {
	position: absolute;
	top: 0;
}

.appbar-round {
	max-width: 1200px;
	border-radius: 80px;
}

::v-deep .ssr-carousel-slides {
	width: 100%;
}

.slider {
	position: relative;
}

.w100 {
	width: 100%;
}

.position-fixed {
	position: fixed;
}

.position-sticky {
	position: sticky;
}

.slide-appbar {
	z-index: 1;
	top: -144px; // solo en mobile
	transition: 0.3s top;

	&.slide-appbar-show {
		top: 0;
	}
}
</style>

